<template>
  <div class="pro-loader" v-if="isLoading">
    <div class="reverse-spinner"></div>
  </div>
  <div v-else>
    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
      >

      <div class="view-change ml-0 mobile-work">
        <div @click="paginationConf.isGrid = true" class="grid-view">
          <i class="fas fa-th-large"></i>
        </div>
        <div @click="paginationConf.isGrid = false" class="list-view">
          <i class="fas fa-list"></i>
        </div>
      </div>

      <!-- pagination -->
      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      ></b-pagination>
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select v-model.number="prodsPerPage">
            <!-- <option value="1">1</option> -->
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change desktop-work">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="middle" v-if="isMobileScreen">
        <div @click="changeMobileCategoryFilterShow" class="filter-btn-mobile">
          <i class="fas fa-filter"></i>
          <span>Filter</span>
        </div>
      </div>

      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>

    <div
      class="row"
      :class="{
        'grid-view': paginationConf.isGrid,
        'list-view': !paginationConf.isGrid,
      }"
    >
      <div
        v-for="product of lists"
        class="col-12 col-md-6 mb-30 col-lg-4 col-xl-3 cst-12"
        :key="product.id"
      >
        <ProductCard
          :product="product"
          :viewType="paginationConf.isGrid ? 'grid-view' : 'list-view'"
        />
      </div>
    </div>

    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
      >
      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      ></b-pagination>
      <!-- @change="backToTop" -->
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select v-model.number="prodsPerPage">
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";

import ProductCard from "./ProductCard.vue";
import CategoryPageContentFilter from "@/base/core/components/category-product/CategoryPageContentFilter.vue";

// import { getProductFiltersByCategoryId } from "@storefront/core/data-resolver/products";

export default {
  name: "FilteredProducts",
  components: {
    ProductCard,
    CategoryPageContentFilter,
  },

  data() {
    return {
      paginationConf: {
        isGrid: true,

        currentPageNr: 1,
        totalProducts: 0,
        perPage: 12,
      },
      isLoading: true,
    };
  },
  async mounted() {
    await this.applyURLFilters();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    showProducts() {
      return this.$store.getters["category/getShowProducts"];
    },
    totalProducts() {
      return this.$store.getters["category/getShowProducts"].length;
    },
    currentPage() {
      return this.$store.getters["category/getCurrentPage"];
    },
    // currentPage: {
    //   get() {
    //     return this.$store.getters["category/getCurrentPage"];
    //   },
    //   set(value) {
    //     this.$store.commit("category/setCurrentPage", value);
    //   },
    // },
    prodsPerPage: {
      get() {
        return this.$store.getters["category/getProductsPerPage"];
      },
      set(value) {
        this.$store.commit("category/setProductsPerPage", value);
      },
    },
    totalProds() {
      return this.$store.getters["category/getTotalProducts"];
    },
    lists() {
      if (this.$store.getters["category/getShowProducts"].length === 0) {
        return [];
      }

      const items = this.$store.getters["category/getShowProducts"];

      const filterdInStock = items.filter(
        (item) => item.stock_status == "IN_STOCK"
      );
      const outOfStock = items.filter(
        (item) => item.stock_status != "IN_STOCK"
      );

      const newProducts = [...filterdInStock, ...outOfStock];

      // Return just page of items needed
      return newProducts;

      // return newProducts.slice(
      //   (this.currentPage - 1) * this.prodsPerPage,
      //   this.currentPage * this.prodsPerPage
      // );
    },
    isMobileScreen() {
      return isMobile;
    },
    priceFilterMin() {
      return this.$store.getters["category/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["category/getPriceFilterMax"];
    },
  },
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    changePage(pageNo) {
      this.$store.commit("category/setCurrentPage", pageNo);
    },
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
    async applyURLFilters() {
      const { sort, page } = this.$route.query;

      const totalPages = this.$store.getters["category/getTotalPages"];

      if (
        !isNaN(Number(page)) &&
        Number(page) > 1 &&
        Number(page) <= totalPages
      ) {
        this.$store.commit("category/addCurrentPage", page);
      }

      if (Number(page) > totalPages) {
        const url = new URL(window.location);
        url.searchParams.delete("page");
        window.history.replaceState({}, "", url);
      }

      if (sort) {
        let currentSorting = null;
        if (sort == "name_up") {
          currentSorting = { sortBy: "name", direction: "ASC" };
        } else if (sort == "name_down") {
          currentSorting = { sortBy: "name", direction: "DESC" };
        } else if (sort == "price_up") {
          currentSorting = { sortBy: "price", direction: "ASC" };
        } else if (sort == "price_down") {
          currentSorting = { sortBy: "price", direction: "DESC" };
        }
        this.$store.commit("category/addCurrentSorting", currentSorting);
      }

      const activeFilters = [];
      let activePriceFilter = [];

      const productFilters = this.$store.getters["category/getProductFilters"];

      Object.keys(this.$route.query).forEach((element) => {
        if (element.startsWith("filter-")) {
          const attribute_code = element.replace("filter-", "");

          const attrFilters = productFilters.find(
            (el) => el.attribute_code == attribute_code
          );

          if (attrFilters) {
            if (attribute_code == "price") {
              const [priceMin, priceMax] = this.$route.query[element].split(
                "_"
              );

              if (!isNaN(parseInt(priceMin)) && !isNaN(parseInt(priceMax))) {
                const min =
                  this.priceFilterMin > parseInt(priceMin)
                    ? this.priceFilterMin
                    : parseInt(priceMin);
                const max =
                  this.priceFilterMax < parseInt(priceMax) ||
                  parseInt(priceMax) <= 0
                    ? this.priceFilterMax
                    : parseInt(priceMax);

                activePriceFilter = [min, max];
              }
            } else {
              this.$route.query[element].split(",").forEach((value) => {
                const activeFilter = attrFilters.options.find(
                  (option) => option.value == value
                );
                if (activeFilter) {
                  const filterBy = {
                    attribute_code: attribute_code,
                    type_label: attrFilters.label,
                    value: value,
                    label: activeFilter.label,
                  };
                  activeFilters.push(filterBy);
                }
              });
            }
          }
        }
      });

      if (activeFilters.length > 0) {
        this.$store.commit("category/addActiveFilter", activeFilters);
      }

      if (activePriceFilter.length > 0) {
        this.$store.commit("category/addPriceFilter", activePriceFilter);
      }

      if (activeFilters.length > 0 || activePriceFilter.length > 0) {
        await this.$store.dispatch("category/loadCategoryFilters");
      } else if (sort || (page && Number(page) <= totalPages)) {
        await this.$store.dispatch("category/loadProductsByFilter");
      }
    },
  },
  watch: {
    currentPage(page) {
      const url = new URL(window.location);
      url.searchParams.set("page", page);
      window.history.replaceState({}, "", url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
