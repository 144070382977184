<template>
  <div>
    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
        <span>{{
          Math.ceil(totalProducts / paginationConf.perPage)
        }}</span></label
      >

      <div class="view-change ml-0 mobile-work">
        <div @click="paginationConf.isGrid = true" class="grid-view">
          <i class="fas fa-th-large"></i>
        </div>
        <div @click="paginationConf.isGrid = false" class="list-view">
          <i class="fas fa-list"></i>
        </div>
      </div>

      <!-- pagination -->
      <b-pagination
        v-model="paginationConf.currentPageNr"
        :total-rows="totalProducts"
        :per-page="paginationConf.perPage"
        class="cst-pagination"
        aria-controls="cat-item"
      ></b-pagination>
      <!-- @change="tmp" -->
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select v-model.number="paginationConf.perPage">
            <!-- <option value="1">1</option> -->
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change desktop-work">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="middle" v-if="isMobileScreen">
        <div @click="changeMobileCategoryFilterShow" class="filter-btn-mobile">
          <i class="fas fa-filter"></i>
          <span>Filter</span>
        </div>
      </div>

      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>

    <div
      class="row"
      :class="{
        'grid-view': paginationConf.isGrid,
        'list-view': !paginationConf.isGrid,
      }"
    >
      <div
        v-for="product of lists"
        class="col-12 col-md-6 mb-30 col-lg-4 col-xl-3 cst-12"
        :key="product.id"
      >
        <ProductCard
          :product="product"
          :viewType="paginationConf.isGrid ? 'grid-view' : 'list-view'"
        />
      </div>
    </div>

    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
        <span>{{
          Math.ceil(totalProducts / paginationConf.perPage)
        }}</span></label
      >

      <!-- pagination -->
      <b-pagination
        v-model="paginationConf.currentPageNr"
        :total-rows="totalProducts"
        :per-page="paginationConf.perPage"
        class="cst-pagination"
        aria-controls="cat-item"
        @change="backToTop"
      ></b-pagination>
      <!-- @change="tmp" -->
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select v-model.number="paginationConf.perPage">
            <!-- <option value="1">1</option> -->
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";

import ProductCard from "@/esf_antarctica_feestenkleding/core/components/category-product/ProductCard.vue";
import CategoryPageContentFilter from "@/base/core/components/category-product/CategoryPageContentFilter.vue";

// import { getProductFiltersByCategoryId } from "@storefront/core/data-resolver/products";

export default {
  name: "FilteredProducts",
  components: {
    ProductCard,
    CategoryPageContentFilter,
  },
  
  data() {
    return {
      paginationConf: {
        isGrid: true,

        currentPageNr: 1,
        totalProducts: 0,
        perPage: 12,
      },
    };
  },
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    totalProducts() {
      return this.$store.getters["category/getShowProducts"].length;
    },
    lists() {
      if (this.$store.getters["category/getShowProducts"].length === 0) {
        return [];
      }
      this.$store.commit("category/doSorting");

      const items = this.$store.getters["category/getShowProducts"];

      // const newProducts = [...items];

      // newProducts.sort((a) => {
      //   if (a.stock_status == "IN_STOCK") {
      //     return -1;
      //   } else {
      //     return 1;
      //   }
      // });

      const filterdInStock = items.filter(
        (item) => item.stock_status == "IN_STOCK"
      );
      const outOfStock = items.filter(
        (item) => item.stock_status != "IN_STOCK"
      );

      const newProducts = [...filterdInStock, ...outOfStock];

      // Return just page of items needed
      return newProducts.slice(
        (this.paginationConf.currentPageNr - 1) * this.paginationConf.perPage,
        this.paginationConf.currentPageNr * this.paginationConf.perPage
      );
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
