<template>
  <div class="content-filter">
    <div
      class="selected-filters d-none d-md-flex flex-wrap align-items-center"
    ></div>
    <div class="select-wrapper">
      <b-form-select
        id="sorting"
        :value="sorting"
        @change="changeSorting"
        :options="selectOptions"
        class="select"
      />
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "CategoryPageContentFilter",
  computed: {
    selectOptions() {
      const sortOpt = [];
      config.sortingOptions.forEach((element) => {
        let value = element + "|" + "ASC";
        if (element == "name_up") {
          value = "name" + "|" + "ASC";
        } else if (element == "name_down") {
          value = "name" + "|" + "DESC";
        } else if (element == "price_up") {
          value = "price" + "|" + "ASC";
        } else if (element == "price_down") {
          value = "price" + "|" + "DESC";
        }
        const option = {
          value,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting() {
      const sort = this.$store.getters["category/getCurrentSorting"];

      let sorting = "default|ASC";
      if (sort != null) {
        const { sortBy, direction } = sort;
        sorting = sortBy + "|" + direction;
      }

      return sorting;
    },
  },
  watch: {
    sorting: function (newSorting) {
      let sortBy = "default";
      if (newSorting == "name|ASC") {
        sortBy = "name_up";
      } else if (newSorting == "name|DESC") {
        sortBy = "name_down";
      } else if (newSorting == "price|ASC") {
        sortBy = "price_up";
      } else if (newSorting == "price|DESC") {
        sortBy = "price_down";
      }

      const url = new URL(window.location);
      url.searchParams.set("sort", sortBy);
      window.history.replaceState({}, "", url);
    },
  },
  methods: {
    changeSorting(sortVal) {
      const [sortBy, direction] = sortVal.split("|");

      let sort = { sortBy, direction };

      if (sortBy == "default") sort = null;

      this.$store.commit("category/setCurrentSorting", sort);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-type {
  font-size: 16px;
  font-weight: normal;
  margin-right: 12px;
}

.filter-items {
  font-size: 14px;
  li {
    margin-top: 10px;
    margin-bottom: 10px;
    + li {
      margin-left: 14px;
    }
  }
}

.filter-items-btn {
  border: 1px solid $borderColor;
  padding: 4px 22px;
  transition: none;
  display: block;

  &:hover {
    color: $white !important;
  }
}

.select-wrapper {
  position: relative;
}

select {
  font-size: 12px;
  border: 1px solid $borderColor;
  padding: 8px 55px 8px 10px;
  line-height: 1;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    box-shadow: none;
    border-color: currentColor;
  }
}

select::-ms-expand {
  display: none;
}
</style>
